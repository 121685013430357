import { AtomicExternalWrapper } from '@components/Search/AtomicExternalWrapper';
import { AtomicSearch } from '@components/Search/AtomicSearch';
import { AtomicSearchInterfaceWrapper } from '@components/Search/AtomicSearchInterfaceWrapper';
import { AtomicSearchContext } from '@contexts/coveo/AtomicSearchContext';
import { useFlags } from '@lib/flags/amplitude/client';
import { FLAG_TREATMENT, Flags } from '@lib/flags/flagConstants';
import { usePathname } from 'next/navigation';
import { getWithoutLocale } from 'utils';
import { HeaderSearch } from './Search';

/* Handles returning either the old Search or the Atomic Coveo Searchbox */
export const SearchWrapper = ({
  searchPlaceholder,
}: {
  searchPlaceholder: string;
}) => {
  const { flags, loading } = useFlags();
  const isCoveoSearchEnabled =
    flags?.[Flags.COVEO_SEARCH]?.value === FLAG_TREATMENT;
  const pathname = usePathname() || '/';
  const isOnACoveoResultsPage =
    getWithoutLocale(pathname) === '/search' ||
    getWithoutLocale(pathname) === '/listing';

  if (loading) {
    return null;
  }

  return (
    <>
      {isCoveoSearchEnabled ? (
        <>
          <AtomicExternalWrapper
            id="atomic-search-external"
            context={AtomicSearchContext}
            atomicSearchInterfaceSelector="#atomic-search-interface_search"
          >
            <AtomicSearch />
          </AtomicExternalWrapper>
          {/*
           * On /search and /listing pages, the content of the page is wrapped in a SearchInterface.
           * On other pages, we need a search interface here to initialize the header searchbox.
           */}
          {!isOnACoveoResultsPage && (
            <AtomicSearchInterfaceWrapper
              id="atomic-search-interface_search"
              shouldExecuteFirstSearch={false}
              reflectStateInUrl={true}
              context={AtomicSearchContext}
            />
          )}
        </>
      ) : (
        <HeaderSearch placeholder={searchPlaceholder} />
      )}
    </>
  );
};
