import Image from 'next/image';

type HeaderLogoProps = {
  imageUrl: string;
  title?: string;
  href?: string;
};

export const HeaderLogo = ({ imageUrl, title, href }: HeaderLogoProps) => {
  const imageElement = (
    <Image
      width="152"
      height="20"
      src={imageUrl}
      alt={title || 'Unity Asset Store'}
      className="w-[118px] md:w-[168px] lg:w-[152px]"
    />
  );

  if (href) {
    return (
      <a href={href} className="focus-outline block w-fit rounded">
        {imageElement}
      </a>
    );
  }

  return imageElement;
};
