import Image from 'next/image';

type FooterLogoProps = {
  title: string;
  src: string;
};

export const FooterLogo = ({ title, src }: FooterLogoProps) => {
  if (!src) {
    return null;
  }

  return (
    <a href="/" className="block w-fit">
      <Image
        width="92"
        height="32"
        src={src}
        alt={title || 'The Unity Asset Store logo'}
        className="mb-10"
      />
    </a>
  );
};
