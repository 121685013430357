'use client';

import { AtomicContext } from '@contexts/coveo/AtomicContext';
import { AtomicExternal } from '@coveo/atomic-react';
import { PropsWithChildren, useContext } from 'react';

/**
 * Wrap atomic components that are external to the AtomicSearchInterfaceWrapper.
 * We should only have 1 main AtomicSearchInterface that interacts with the URL of the page, otherwise it can end up pushing state multiple times.
 * The AtomicSearchInterface should wrap around the atomic components, we use AtomicExternal to wrap around our standalone searchbox in the Header.
 *
 * https://docs.coveo.com/en/atomic/latest/reference/components/atomic-external/
 */
export const AtomicExternalWrapper = ({
  id,
  atomicSearchInterfaceSelector,
  context,
  children,
}: PropsWithChildren<{
  id: string;
  atomicSearchInterfaceSelector: string;
  context: AtomicContext;
}>) => {
  const { searchEngine } = useContext(context);
  // only render any Atomic components after the engine is initialized
  if (!searchEngine) {
    return null;
  }

  return (
    <AtomicExternal id={id} selector={atomicSearchInterfaceSelector}>
      {children}
    </AtomicExternal>
  );
};
