'use client';

import c from 'classnames';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { CartProvider } from '@contexts/CartContext';
import { OrganizationsProvider } from '@contexts/OrganizationsContext';
import { HeaderStateContext } from '@contexts/layout/HeaderStateContext';
import {
  FooterMenuList,
  Header as HeaderType,
  Microcopy,
  Navigation,
} from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import { User } from '@lib/types/common/User';
import { isDefined } from '@lib/util/isDefined';
import { formatContentfulMicrocopy } from '@lib/util/microcopy';
import { toNonNullable } from '@lib/util/toNonNullable';
import { FastActionMenu } from '../FastActionMenu/FastActionMenu';
import { useScrollDirection } from './../../../lib/util/useScrollDirection';
import { HeaderLogo } from './Logo';
import { DesktopMenu } from './Navigation/Desktop/DesktopMenu';
import { MobileNavigationMenuModal } from './Navigation/Mobile/MobileNavigationMenuModal';
import { contentfulNavFormat } from './Navigation/contentfulNavFormat';
import { SearchWrapper } from './SearchWrapper';
import { SkipToMainButton } from './SkipToMainButton';
import { UserMenu } from './UserMenu/UserMenu';

type Props = {
  user?: User;
  headerData: HeaderType;
  microcopy?: Microcopy[];
  languageMenu?: Maybe<FooterMenuList>;
};

export const Header = ({
  user,
  headerData,
  microcopy,
  languageMenu,
}: PropsWithChildren<Props>) => {
  const scrollDirection = useScrollDirection();
  const microcopyObject = microcopy ? formatContentfulMicrocopy(microcopy) : {};
  const searchPlaceholder =
    microcopyObject['layout.search-placeholder'] ?? 'Search for assets';
  const signedInProfileMenuItems =
    headerData.signedInUserMenuCollection?.items.filter(isDefined) || [];
  const signedOutProfileMenuItems =
    headerData.signedOutUserMenuCollection?.items.filter(isDefined) || [];
  const contentfulHeaderNavigation =
    headerData.navigationItemsCollection?.items;
  const navigationItems = contentfulNavFormat(
    (contentfulHeaderNavigation as Navigation[]) || []
  );
  const fastActionItems = headerData.fastActionsMenuCollection?.items;
  const logoImage = toNonNullable(headerData.logo?.url);
  const currencyAriaLabel = microcopyObject['layout.currency'] || 'Currency';
  // need to start screenSize at null, because on the server render we don't know yet what the screen size is
  // if we render desktop or mobile version by default, and its actually the other it will result in a Next hydration error since the server and client render will be different
  const [screenSize, setScreenSize] = useState<null | 'desktop' | 'mobile'>(
    null
  );
  const { stickyHeaderEnabled } = useContext(HeaderStateContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setScreenSize('desktop');
      } else {
        setScreenSize('mobile');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header
      className={c(
        'z-header relative top-0 bg-black text-white transition ease-linear lg:pb-0',
        {
          '-translate-y-full overflow-hidden': scrollDirection === 'down', //overflow hidden to prevent firefox scroll bug
          sticky: stickyHeaderEnabled,
        }
      )}
    >
      <SkipToMainButton
        label={microcopyObject['layout.skip-to-main-content']}
      />
      <div className="lg-container grid grid-cols-12 items-center py-4 lg:pb-0">
        <div className="col-span-6 mr-8 lg:col-span-3" data-test="header-logo">
          {logoImage && (
            <HeaderLogo
              imageUrl={logoImage}
              title={toNonNullable(headerData.logo?.description)}
              href={'/'}
            />
          )}
        </div>
        {/*
         * Placeholder that takes up the same width as the search element, so that the element to the right of the search doesn't shift after first client render
         * Needed because margin-left: auto doesn't work in CSS grid, and because the search element isn't loaded in until first useEffect runs that sets the screenSize
         */}
        <div
          className={c('hidden lg:col-span-6 lg:block', {
            'lg:hidden': screenSize === 'desktop',
          })}
        />
        {/* Search Desktop */}
        {screenSize === 'desktop' && (
          <div className="col-span-12 mt-0 hidden w-auto lg:col-span-6 lg:block lg:max-w-search-bar">
            <SearchWrapper searchPlaceholder={searchPlaceholder} />
          </div>
        )}
        <div className="col-span-6 ml-auto flex md:relative lg:col-span-3">
          {fastActionItems && (
            <CartProvider user={user}>
              <FastActionMenu
                showLoggedInOptions={Boolean(user)}
                items={fastActionItems}
              />
            </CartProvider>
          )}
          <OrganizationsProvider user={user}>
            <UserMenu
              user={user}
              microcopy={microcopyObject}
              sections={
                user ? signedInProfileMenuItems : signedOutProfileMenuItems
              }
            />
          </OrganizationsProvider>
          {/* Navigation Mobile */}
          <div className="lg:hidden">
            <MobileNavigationMenuModal
              navigationItems={navigationItems}
              currencyAriaLabel={currencyAriaLabel}
              microcopy={microcopyObject}
              languageMenu={languageMenu}
            />
          </div>
        </div>
        {/* Search Mobile */}
        {screenSize === 'mobile' && (
          <div className="col-span-12 mt-8 lg:hidden">
            <SearchWrapper searchPlaceholder={searchPlaceholder} />
          </div>
        )}
        {/* Navigation Desktop */}
        <nav className="col-span-12 mt-4 hidden w-full lg:block">
          <DesktopMenu navigationItems={navigationItems} />
        </nav>
      </div>
    </header>
  );
};
