import c from 'classnames';
import { CaretDown } from 'phosphor-icons';
import React, { useState } from 'react';

import { Overlay } from '@components/Layout/Overlay';
import { NavigationItem } from '../types';
import { MegaMenu } from './MegaMenu';

export type DesktopMenuItemProps = {
  item: NavigationItem;
  className?: string;
};

export const DesktopMenuItem = ({
  item,
  className = '',
}: DesktopMenuItemProps) => {
  const [isActive, setIsActive] = useState(false);
  const doesSubMenuExist = item.columns.length !== 0;
  const listItemRef = React.useRef<HTMLLIElement>(null);
  const itemId = item.mainLink.value.replace(' ', '-');

  const toggleSubNavigation = () => {
    isActive ? closeSubNavigation() : openSubNavigation();
  };

  const openSubNavigation = () => {
    setIsActive(true);

    // move the border indicator

    const PADDING_AMOUNT = 16; // px
    const LEFT_AND_RIGHT_PADDING = 2 * PADDING_AMOUNT; // px
    const VISUAL_ALIGNMENT_OFFSET = 1; // px

    const activeIndicatorBorder =
      document.querySelector<HTMLElement>('#active-indicator');
    const listItemElement = listItemRef?.current;

    if (activeIndicatorBorder && listItemElement) {
      const left =
        listItemElement.offsetLeft + PADDING_AMOUNT + VISUAL_ALIGNMENT_OFFSET;
      const width = listItemElement.offsetWidth - LEFT_AND_RIGHT_PADDING;

      activeIndicatorBorder.style.left = `${left}px`;
      activeIndicatorBorder.style.width = `${width}px`;
      activeIndicatorBorder.style.opacity = '1';
    }
  };

  const closeSubNavigation = () => {
    setIsActive(false);

    // remove the border indicator
    const activeIndicatorBorder =
      document.querySelector<HTMLElement>('#active-indicator');
    if (activeIndicatorBorder) {
      activeIndicatorBorder.style.opacity = '0';
    }
  };

  // close sub navigation when focus leaves the list item and its sub contents
  const onListItemBlur = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      closeSubNavigation();
    }
  };

  const onListItemKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeSubNavigation();
    }
  };

  return (
    <li
      ref={listItemRef}
      className={c('first-of-type:-ml-4 last-of-type:-mr-4', className)}
    >
      <div
        role="presentation"
        onMouseEnter={openSubNavigation}
        onMouseLeave={closeSubNavigation}
        onKeyDown={onListItemKeyDown}
        onBlur={doesSubMenuExist ? onListItemBlur : undefined}
      >
        <div className="relative flex">
          <a
            className={c(
              'focus-outline block cursor-pointer rounded-sm px-4 py-4 transition',
              {
                'text-blue-dark': isActive,
              }
            )}
            href={item.mainLink.href}
          >
            {item.mainLink.value}
          </a>
          {doesSubMenuExist && (
            <button
              aria-label={item.mainLink.value}
              aria-expanded={isActive}
              aria-controls={itemId}
              onClick={toggleSubNavigation}
              className="focus-outline pointer-events-none absolute bottom-0 right-0 top-0 rounded-sm fill-white px-0.5 opacity-0 transition focus:pointer-events-auto focus:opacity-100"
            >
              <CaretDown size={8} className={c({ 'rotate-180': isActive })} />
            </button>
          )}
        </div>
        <div
          data-test={`${itemId}-menu`}
          className={c({
            hidden: !isActive,
          })}
        >
          {doesSubMenuExist && (
            <>
              <Overlay />
              <MegaMenu id={itemId} columns={item.columns} />
            </>
          )}
        </div>
      </div>
    </li>
  );
};
