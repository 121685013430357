import { Link as Linktype } from '@graphql/generated-contentful/graphql';
import { FooterMenuItem } from './FooterMenuItem';
import { FooterMenuLabel } from './FooterMenuLabel';
type FooterMenuListProps = {
  label?: string;
  items?: Linktype[];
};

export const FooterMenuList = ({ label, items }: FooterMenuListProps) => {
  return (
    <ul className="col-span-6 md:col-span-4 lg:col-span-2">
      <FooterMenuItem>
        {label && <FooterMenuLabel text={label} />}
        <ul>
          {items?.map((item) => {
            return (
              <FooterMenuItem key={item.title}>
                <a
                  className="focus-outline rounded text-grey-500 hover:text-blue-dark"
                  href={item.url!}
                  target={item.openInNewTab ? '_blank' : '_self'}
                >
                  {item.value}
                </a>
              </FooterMenuItem>
            );
          })}
        </ul>
      </FooterMenuItem>
    </ul>
  );
};
